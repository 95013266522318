import SkinThicknessTemplate from '@/modules/questionnaire/components/steps/common/identity/skin-thickness/SkinThicknessTemplate';

import { SKIN_THICKNESS } from '@/modules/questionnaire/api/constants';

export default {
  title: 'Steps/Identity/SkinThickness/SkinThicknessTemplate',
  component: SkinThicknessTemplate
};

const createStory = props => () => ({
  components: { SkinThicknessTemplate },
  storyProps: props,
  wrapperStyles: {
    display: 'flex',
    justifyContent: 'center'
  },
  template:
    '<div :style="$options.wrapperStyles"><skin-thickness-template v-bind="$options.storyProps" /></div>'
});

export const PatientsWithThinSkin = createStory({
  skinThickness: SKIN_THICKNESS.THIN
});

export const PatientsWithNormalSkin = createStory({
  skinThickness: SKIN_THICKNESS.NORMAL
});
